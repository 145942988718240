/* Light Mode */
:root {
    --body_backgrund: white;
    --body_color: black;
    --link_color: navy;
}

/* Dark Mode */
[data-theme="dark"] {
    --body_backgrund: #282c34;
    --body_color: white;
    --link_color: cyan;
}

* {
    transition: all 0.3s ease-in-out;
}

.svg-icon {
    width: 40px; /* Set the desired width */
    height: 40px; /* Set the desired height */
}

.dark-mode-container {
    position: fixed;
    top: 32px; /* Adjust the distance from the top as needed */
    right: 32px; /* Adjust the distance from the right as needed */
    z-index: 1000; /* Ensure it's above other content */
}

.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 16px;
}

    .toggle-button.dark {
        color: #fff;
    }

    .toggle-button.light {
        color: #333;
    }